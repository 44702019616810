<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
// import appConfig from "@/app.config";
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import router from '@/router'



export default {
  // page: {
  //   title: "Add Port",
  //   meta: [{ name: "description", content: appConfig.description }]
  // },
  components: { Layout, PageHeader },

  setup(props){
    const { $put } = useHttp()
    const { $get } = useHttp()
    const form = ref({
      ordManifestno: ''
    })
    const formdisplay = ref({
      orderNumber: '',
      customerName:'',
      airlinesName:'',
      flight:'',
      weight:''
    })

    const getData = async () => {
      const {data} = await $get({ url: 'master/order/' + props.order_id})
      formdisplay.value.orderNumber = data.ord_no
      formdisplay.value.customerName = data.customer.name
      formdisplay.value.airlinesName = data.schedule.vendor.name
      formdisplay.value.weight = data.ord_weight + 'Kg'
      formdisplay.value.flight = `${data.schedule.sch_flight_no} (${data.schedule.originport.prt_initial} - ${data.schedule.destinationport.prt_initial})`
      //formdisplay.value.flight = data.schedule.sch_flight_no + "("+schedule.originport.prt_initial+" - "+schedule.destinationport.prt_initial+")"
    }

    const validationForm = async () => {
       $put({ url: 'order2/manifestno/'+props.order_id,
        data: form.value
      })
        .then(() => {
          // isLoading.value = false
          router.push({ name: 'apps-manageorder-list' })
        })
        .catch(() => {
          // isLoading.value = false
        })

  }
    onMounted(() => {
      getData()
    })
    return {
      form,
      formdisplay,
      validationForm
    }
  },
  props:{
      order_id: String
  },
   data() {
     return {
         title: "Input SMU Number",
       items: [
         {
           text: "Master Data"
         },
         {
          text: "Home",
          active: true
         }
       ],
       select_label: "Select"
    };
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order No"
                    label-for="order_no"
                  >
                    <b-form-input id="order_no" name="order_no" v-model="formdisplay.orderNumber" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Customer Name"
                    label-for="customer_name"
                  >
                    <b-form-input id="customer_name" name="customer_name" v-model="formdisplay.customerName" disabled></b-form-input>
                  </b-form-group>

                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Flight No"
                    label-for="flight_no"
                  >
                    <b-form-input id="flight" name="flight" v-model="formdisplay.flight" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Weight"
                  >
                    <b-form-input  v-model="formdisplay.weight" disabled></b-form-input>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="SMU Number"
                    label-for="smu_number"
                  >
                    <b-form-input id="smu_number" name="smu_number" v-model="form.ordManifestno"></b-form-input>
                  </b-form-group>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
   
   
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 text-center">
                <div class="button-items">
                  <b-button variant="primary" @click.prevent="validationForm">
                    <i class="ri-save-line align-middle ml-2"></i>
                    Submit
                  </b-button>
                  <b-button variant="danger" :to="{name: 'apps-manageorder-list'}">
                    <i class="ri-arrow-left-line align-middle mr-2"></i> Back
                  </b-button>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>